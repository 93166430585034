import '../styles/thankyou.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
// import { InlineWidget } from "react-calendly";


export default function ThankYou() {
  // const url = 'http://localhost:3999'
  const url = 'https://dev.pmwwebdesign.com'

  // const [fromForm, setFromFrom] = useState(false);
  const [date, setDate] = useState('');
  const [clientDetails, setClientDetails] = useState({});


  // const calendarStyles = {
  //   minWidth: '80%',
  //   height: '80vh',
  //   margin: '0 auto',
  // }

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    let finalDate = `${year}-${month}`

    setDate(finalDate)

    let params = new URLSearchParams(window.location.search)

    // if (params.has('form')) {
    //   setFromFrom(true)
    // }

    let details = localStorage.getItem('masterClient')
    let finalDetails = JSON.parse(details)

    if (details){
      setClientDetails(finalDetails)
      handleDetails(finalDetails)
    }
  }, [date])

  const handleDetails = async (details) => {
    await axios.post(`${url}/mastermind/complete`, details)
    .then((res) => {
      console.log(res.data)
      localStorage.removeItem('masterClient')
    }).catch((err) => {
      console.log(err)
    })
  }




  return (
    <main className="thank__you">
      <h2>Thank you for applying to join Business Mastermind</h2>

      {/* {fromForm ? (
        <>
          <h3>Please book a meeting with the team</h3>
          <InlineWidget url={`https://calendly.com/d/cmjc-tsw-4rq/business-academy?month=${date}`}
            styles={calendarStyles}
          />
        </>
      ) : null} */}
    </main>
  )
}