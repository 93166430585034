import { useState, useEffect } from "react";
import { InputLabel, MenuItem, Select, Alert, Checkbox, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import '../styles/register.scss'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from "./CheckOutForm";

const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');
// const stripe = await loadStripe('pk_test_HGDc5rMpzlE4NJvFygwF1zIW');



export default function Register() {
  const today = moment();
  const saleDate = '2024/10/07 09:00:00';
  const evergreenDate = '2024/10/14 09:00:00';

  // const [first, setFirst] = useState(true);
  const [registration, setRegistration] = useState(true);
  const [sale, setSale] = useState(false);
  const [evergreen, setEvergreen] = useState(false)
  const [name, setName] = useState('');
  const [last, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [personalDetails, setPersonalDetails] = useState(true)
  const [consent, setConsent] = useState(false);
  const [options, setOptions] = useState({});
  const [loader, setLoader] = useState(true)
  const url = 'https://dev.pmwwebdesign.com';
  // const url = 'http://localhost:3999';
  const navigate = useNavigate();
  const [tcs, setTcs] = useState(false)


  // !ALERTS
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    // If sale has not started
    if (today.diff(moment(saleDate), 'seconds') > 0 && today.diff(moment(evergreenDate), 'seconds') < 0) {
      let secret;
      let amouunt = {
        amount: 600000
      }
      axios.post(`${url}/mastermind/secret`, amouunt).then((res) => {
        secret = res.data.client_secret
        setOptions({ clientSecret: secret, })
        // setLoader(false)
      }).catch((err) => {
        console.log(err)
      })
      setRegistration(false)
      setSale(true)

    } else if (today.diff(moment(evergreenDate), 'seconds') > 0) {
      setEvergreen(true)
      setSale(false)
    }

  }, [])

  const handleApply = () => {

    if (name === "" || last === "" || email === "" || phone === "" || !consent) {
      setMessage("All fields are required")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else if (!consent) {
      setMessage("Please consent to data usage")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      let data = {
        firstName: name,
        lastName: last,
        email: email,
        phone: phone,
        consent: consent,
      }

      axios.post(`${url}/mastermind/opt-in`, data)
        .then((res) => {
          navigate("/thank-you")
        }).catch((err) => {
          console.log(err)
          setMessage("Something went wrong, please try again")
          setSeverity("warning")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
        })
    }
  }

  const handleNext = () => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === "" || phone === "") {
      setMessage("Please fill in email and phone")
      setSeverity('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else if (!email.match(regex)) {
      setMessage("Please insert an email address")
      setSeverity('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else {
      // TODO: send the data to the db
      let cartData = {
        email: email,
        phone: phone,
      }

      axios.post(`${url}/mastermind/abandon-cart`, cartData)
      .then((res) => {
        console.log(res.data)
        setPersonalDetails(false)
      }).catch((err) => {
        console.log(err)
        setMessage("Something went wrong, please try again")
        setSeverity('error')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2500)
      })
    }
  }

  const handleTc = (value) => {
    console.log(value)
    setTcs(value)
  }

  const manageError = (error) => {
    setMessage(error)
    setSeverity('error')
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2500)
  };




  return (
    <main className="register">
      {registration ? (
        <>
          <h2>Business Mastermind Opt-In Form</h2>
          <section className="form">
            <section className="form__row">
              <TextField
                required
                label="First Name"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setName(e.target.value)}
              />

              <TextField
                required
                label="Last Name"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setLast(e.target.value)}

              />
            </section>
            <section className="form__row">
              <TextField
                required
                label="Email address"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                required
                label="Phone number"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setPhone(e.target.value)}
                pattern='[0-9]*'
              />
            </section>

            <section className="form__row" style={{ marginTop: '2%' }}>
              <FormControlLabel required control={<Checkbox sx={{ alignSelf: "flex-start", marginTop: "-0.5%" }} onChange={(e) => setConsent(e.target.checked)} />} label="I agree that my data may be used for any information related to the products and services offered by Paul McFadden Wealth. I can easily revoke this by simply writing to mail@teampmw.com" className="check--box" />
            </section>
          </section>
          <button className="app__button" onClick={handleApply}>Opt-In Now</button>
        </>
      ) : sale ? (
        <>
          <h2>Join The Elite Business Mastermind NOW</h2>
          {personalDetails ? (
            <section className="billing__details">

              <section className="form--row">
                <div>
                  <label for="emailI">Email <span id="email">Required field</span></label>
                  <input type="email" id="emailI" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div>
                  <label for="emailI">Phone <span id="email">Required field</span></label>
                  <input type="number" id="emailI" onChange={(e) => setPhone(e.target.value)} pattern='[0-9]*' />
                </div>
              </section>
              <button className='submit__bttn' onClick={handleNext}>Next</button>
            </section>
          ) : (
            <>
              <Elements stripe={stripe} options={options}>
                <CheckoutForm error={manageError} email={email} phone={phone} tc={tcs} />
              </Elements>
              <section className="form--row">
                <FormControlLabel control={<Checkbox />} label="I confirm I have read and agree to the terms & conditions" onChange={(e) => handleTc(e.target.checked)} />
              </section>

            </>
          )}
        </>
      ) : evergreen ? (
        <>
          <h2>Evergreen</h2>
        </>
      ) : null}
      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : null}
    </main>
  )
}