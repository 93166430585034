import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Privacy from './components/pages/Privacy';
import Register from './components/pages/Register';
import ThankYou from './components/pages/ThankYou';
import Protege from './components/pages/Protege';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy-policy" element={<Privacy />}/>
          <Route path="register" element={<Register />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="protege" element={<Protege />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
