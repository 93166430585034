import '../styles/howto.scss';
import { useNavigate } from 'react-router-dom';

export default function HowTo(){
  const navigate = useNavigate()

  return(
    <section className="how__to">
      <h2>hot to enroll in the elite business mastermind</h2>
      <section className="how__to__boxes">
        <div className='how__to__box'>
          <h3>Scholarship Price</h3>
          <h4>£5,000 + VAT</h4>
          <button className='how__btn' onClick={() => navigate("/register")}>Join Now</button>
        </div>

        <div className='how__to__box'>
          <h3>Price Increase</h3>
          <h4>£7,000 + VAT</h4>
          <h5>From 14th October 2024</h5>
        </div>

      </section>

    </section>
  )
}