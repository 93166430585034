import "./styles/home.scss";
import ReactPlayer from "react-player";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import Testimonials from "./pages/Testimonials";
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Stack, FormGroup, FormControlLabel, Switch } from "@mui/material";
import strategy from './images/strategy.webp';
import event from './images/event.webp';
import telegram from './images/support_group.webp';
import online from './images/ZoomCall.webp';
import paul from './images/paul_bio.webp';
import retreat from './images/retreat.webp';
import TimeLine from "./pages/Timeline";
import thumb from './images/thumb.webp'
import HowTo from "./pages/HowTo";
import moment from 'moment'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  '@media(max-width: 700px)': {
    width: '90vw',
    maxHeight: '80vh',
  }

};

export default function Home() {
  const navigate = useNavigate();
  const [showNonEssential, setShowNonEssential] = useState(false)
  const [waitingList, setWaitingList] = useState(true)
  const firstPhase = '2024/10/07 09:00:00';
  const today = moment();



  // COOKIES
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    let cookies = localStorage.getItem('pmw_cookies')
    if (cookies) {
      handleClose()
    }

    if (today.diff(moment(firstPhase), 'seconds') > 0) {
      setWaitingList(false)
    }
  }, []);

  const handleCookies = () => {
    let pmw_cooks = true

    localStorage.setItem('pmw_cookies', pmw_cooks)
    handleClose();
  }


  const handleCustomise = () => {
    setShowNonEssential(true)
  }

  function gtag() { window.dataLayer.push(arguments); }


  const handleConsent = (cookie, value) => {
    if (value == false) {
      gtag('consent', 'update', {
        [cookie]: 'denied'
      })
    } else {
      gtag('consent', 'update', {
        [cookie]: 'granted'
      })
    }
  }

  const handleDecline = async () => {
    await gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });

    handleClose()
  }

  return (
    <main className="home__main">
      <section className="hero">
        <h1>The Elite Business Mastermind</h1>
        <p>Scale Faster, Achieve More, and Accelerate Your Business Growth</p>
        <div className='player--wrapper'>
          <ReactPlayer
            url="https://vimeo.com/998608532?share=copy"
            controls
            style={{ aspecRatio: "16/9" }}
            className="video--player"
            light={thumb}
            width='100%'
            height='100%'

          />
        </div>
        {waitingList ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join The Waiting List
          </button>
        ) : (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join Now
          </button>
        )}
      </section>
      {/* WHAT IS COVERED */}
      <section className="includedSection">
        <h2>What You'll achieve</h2>
        <hr />

        <p className="text">By joining the Elite Business Mastermind, you’ll gain the skills and knowledge essential for achieving long-term success in business.</p>
        <p className="text">This programme isn’t just about learning, it’s about
          delivering real, measurable results. You’ll be empowered with practical strategies that can be
          implemented immediately, accelerating your growth and positioning your business for sustainable
          success.</p>
        <strong>Here’s what you can expect to achieve:</strong >

        <section className="included">
          <section className="column">
            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Master Business Foundations for Scalability</h6>
              </aside>
              <p>
                Build a business that adapts and thrives. Master the core foundations essential for long-term growth,
                profitability, and scalability.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Strategic Planning with Clarity</h6>
              </aside>
              <p>
                Develop clear, actionable strategies that guide your next business moves with confidence. Gain
                clarity on your business goals and create a roadmap that drives consistent results.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Sales &amp; Marketing Mastery</h6>
              </aside>
              <p>
                Become skilled at closing deals and attracting premium clients. Optimise your sales and digital
                marketing strategies to drive revenue, increase conversions, and grow your brand’s reach.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Systemise &amp; Automate for Efficiency</h6>
              </aside>
              <p>
                Streamline your operations by implementing systems and automation that free up your time, reduce
                workload, and allow you to focus on high-impact growth strategies.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Strengthen Client Retention and Loyalty</h6>
              </aside>
              <p>
                Build strong, lasting client relationships by delivering consistent value and personalised service.
                Gain repeat business and referrals to fuel sustainable growth.
              </p>
            </section>
          </section>

          <section className="column">
            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Build a Resilient, Winning Mindset</h6>
              </aside>
              <p>
                Break through fear, procrastination, and self-doubt. Cultivate resilience and confidence,
                empowering you to make quick, decisive actions that keep your business moving forward.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Position Yourself as an Industry Authority</h6>
              </aside>
              <p>
                Establish yourself as a leading expert in your field. Build credibility and attract high-quality clients
                by becoming the go-to authority in your industry.
              </p>
            </section>


            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Expand Your Network &amp; Access Expert Support</h6>
              </aside>
              <p>
                Connect with a community of like-minded entrepreneurs and industry experts. Gain real-time
                feedback, accountability, and valuable insights to accelerate your business growth.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Achieve Work-Life Balance</h6>
              </aside>
              <p>
                Simplify your business processes and delegate tasks effectively. Increase revenue without burnout,
                creating space for both professional &amp; personal success.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Develop Life-Long Business Skills</h6>
              </aside>
              <p>
                By Being Part Of The Elite Business Mastermind, You'll Be Fully Equipped To Grow Your
                Business, Overcome Obstacles, And Achieve Your Business Goals.
              </p>
            </section>
          </section>
        </section>
      </section>
      <section className="skills">
        <h2>By being part of the elite mastermind, you'll be fully equipped to grow your business,
          overcome obstacles, and achieve your business goals.</h2>
      </section>
      <section className="what__is">
        <h2>How You’ll Achieve Results</h2>
        <hr />
        <p>The Elite Business Mastermind is a results-driven, 12-month programme that helps your business
          grow.</p>
        <p>With workshops every quarter, monthly coaching, and support from experts, you'll learn to
          work smarter and focus on growing your business. You’ll also join a community of successful
          business owners.</p>
        <p>Through ongoing guidance, you'll build strong foundations, overcome challenges,
          and achieve real results. This programme will help you grow your business, make more money, and
          reach your full potential.</p>
        {waitingList ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join The Waiting List
          </button>
        ) : (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join Now
          </button>
        )}
      </section>

      {/* INCLUDED */}
      <section className="extras">
        <h2>More About The Elite Business Mastermind</h2>
        <hr />
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Live In-Person Events</h4>
            <p>Over the year, you’ll attend four in-person events that deliver real results.
              Each session equips you with actionable strategies that drive immediate business growth, ensuring
              measurable progress. All event recordings are available for continuous learning.</p>
          </aside>
          <img src={event} alt="In Person Event" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Strategy Planning</h4>
            <p>At each event, you’ll receive a detailed strategy plan, ensuring you stay on
              track and achieve consistent business milestones. With a clear strategy you can accurately measure
              your results, adjust your strategies as needed and achieve ongoing improvement and growth.</p>
          </aside>
          <img src={strategy} alt="Private Community" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Online Coaching Sessions</h4>
            <p>Monthly coaching ensures you remain focused on results, offering
              expert guidance and accountability to keep your momentum. Each session builds on the knowledge
              gained from the live events, allowing you to ask questions and get expert advice.</p>
          </aside>
          <img src={online} alt="Online Sessions" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Private Support Group</h4>
            <p>Gain 24/7 access to real-time problem-solving and immediate feedback
              from peers and mentors. This collaborative space allows you to overcome challenges, network with
              like-minded entrepreneurs, and explore new growth opportunities with expert guidance.</p>
          </aside>
          <img src={telegram} alt="Private telegram group" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Annual Retreat</h4>
            <p>As an Elite Business Mastermind member, you’re invited to an exclusive retreat
              that combines business development with relaxation. Strengthen connections with fellow
              entrepreneurs, recharge, and return with fresh strategies to apply directly to your business for
              ongoing success.</p>
          </aside>
          <img src={retreat} alt="PMW Retreat" />
        </section>

        {waitingList ? (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join The Waiting List
          </button>
        ) : (
          <button
            className="enrol_button"
            onClick={() => navigate("/register")}
          >
            Join Now
          </button>
        )}
      </section>

      {/* WHO IS IT FOR */}
      <section className="includedSection" >
        <h2>The Elite Business Mastermind Is For You If</h2>
        <hr></hr>
        <section className="included">
          <section className="column">
            <section className=" left wiif test" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Start-Up Business Owner:</h6>
              </aside>
              <p>
                In the early years and eager to grow fast? You’ll learn proven ways
                to generate leads, close more sales, and drive revenue growth, setting a solid foundation for long-
                term success.
              </p>
            </section>

            <section className=" left wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Struggling to Scale:</h6>
              </aside>
              <p>
                Has your business hit a plateau? We’ll help you identify bottlenecks, unlock
                new opportunities, and implement strategies to reignite growth and achieve the next level of
                success.
              </p>
            </section>
          </section>

          <section className="column">
            <section className=" right wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Stuck in Daily Operations:</h6>
              </aside>
              <p>
                Overwhelmed by daily tasks? We’ll show you how to streamline
                operations, delegate tasks, and focus on high-impact activities that accelerate growth.
              </p>
            </section>

            <section className=" right wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>
                  Transitioning from 9-5:
                </h6>
              </aside>
              <p>
                Want to leave your job and build your own thriving business? We guide
                you step-by-step, from refining your idea to scaling it into a profitable business, ensuring a smooth
                transition and sustained success.
              </p>
            </section>
          </section>
        </section>
      </section>

      {/* <TimeLine /> */}
      {waitingList ? (
        <section className="wait__sect">
          <h2>To Find Out More</h2>
          <button className="wait--btn" onClick={() => navigate("/register")}>Join the waiting List</button>

        </section>
      ) : (
        <HowTo />
      )}
      <section className="learn" >
        <h2>
          By the End of The 12 Months, You Will
        </h2>
        <hr />
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Mastered Business Foundations</h4>
            <p>Gained a thorough understanding of the six core pillars of
              business, providing you with the essential knowledge to build a strong foundation that
              supports your sustainable growth and scalability.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Transition from Working IN to ON Your Business</h4>
            <p>Build systems and procedures that
              reduce reliance on your daily involvement, empowering you to focus on growth strategies
              and higher-level decisions that drive business success.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Create &amp; Execute Effective Strategic Plans</h4>
            <p>Develop and implement clear, actionable
              strategic plans that keep you on track toward your goals with precision and confidence,
              consistently moving you toward measurable outcomes.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Develop A Winning Mindset</h4>
            <p>Cultivate the mindset to conquer fear, procrastination, and
              self-doubt. Build resilience and leadership confidence, enabling you to tackle challenges and
              take decisive action that drives growth.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Enhance Sales &amp; Marketing Skills</h4>
            <p>Become comfortable with selling, improving your
              ability to close deals, attract premium clients, and craft pricing strategies that maximise
              profitability while enhancing your brand’s appeal.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Leverage Automation for Efficiency</h4>
            <p>Integrate automation tools to streamline processes,
              reduce workload, improve service delivery, and accelerate your ability to scale effectively,
              all while increasing client satisfaction.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Build a Strong Network &amp; Support System</h4>
            <p>Establish valuable relationships with a
              supportive community of entrepreneurs, gaining insights, accountability, and opportunities
              for collaboration that will fuel your growth.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Achieve Work-Life Balance</h4>
            <p>Master time management and implement systems that align
              your business with your lifestyle. You’ll enjoy higher income without the risk of burnout,
              ensuring both professional &amp; personal success.</p>
            <hr />
          </section>
        </section>
      </section>

      {/* TODO: Section under */}


      <Testimonials />
      <section className="bio" >
        <img src={paul} alt="Paul McFadden" />
        <article>
          <h2>Paul McFadden</h2>
          <p>
            Paul McFadden is a renowned entrepreneur, award-winning educator in wealth building, and a
            leading authority on business growth and wealth creation. Starting his entrepreneurial journey at
            just 19, Paul quickly built a multi-million-pound property portfolio, achieving financial freedom
            and millionaire status by the age of 26. Leveraging his early success, he expanded into multiple
            business ventures, collectively generating an annual eight-figure turnover.
          </p>
          <p>
            With a proven track record of scaling businesses, Paul has become a trusted mentor and coach to
            both aspiring entrepreneurs and seasoned business owners. His insights and strategies have
            empowered thousands to grow their businesses and attain financial independence. Recognised
            globally for his expertise, Paul is a highly sought-after speaker at prestigious business and property
            events.
          </p>
          <p>
            His contributions to wealth education have earned him an honorary membership in Robert
            Kiyosaki’s Wealth Master Panel. In 2022, Paul further cemented his status as a global business
            leader by joining the prestigious ClickFunnels Eight-Figure Club, having generated over
            £10,000,000 in revenue from a single offering.
          </p>
        </article>
      </section>
      {/* <FAQ background={background} /> */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            We Use Cookies
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We use cookies to ensure the best experience on our website. These help us improve our site performance, personalise content, and deliver targeted ads.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            By clicking "Accept All Cookies," you agree to the use of all cookies. You can manage your preferences by selecting "Customise Settings" or view our full Cookie Policy for more information.
          </Typography>
          {showNonEssential ? (
            <Stack>
              <FormGroup>
                <FormControlLabel disabled control={<Switch defaultChecked />} label="Essential Cookies" />
                <FormControlLabel control={<Switch defaultChecked />} label="Performance Cookies" onChange={(e) => handleConsent('performance', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Functionality Cookies" onChange={(e) => handleConsent('ad_personalization', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Analytics Cookies" onChange={(e) => handleConsent('analytics_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Adverstising Cookies" onChange={(e) => handleConsent('ad_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Social Media Cookies" onChange={(e) => handleConsent('ad_user_data', e.target.checked)} />
              </FormGroup>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept Custom</Button>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept All</Button>
              </Stack>

            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
              <Button variant="outlined" color="success" onClick={handleCookies}>Accept Cookies</Button>
              <Button variant="outlined" color="primary" onClick={handleCustomise}>Customise Settings</Button>
              <Button variant="outlined" color="success" onClick={handleDecline}>Decline Non-Essential</Button>

            </Stack>

          )}

        </Box>
      </Modal>
    </main>
  );
}
