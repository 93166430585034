import logo from "./images/Logo.webp";
import "./styles/navFooter.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

export default function Header() {
  const navigate = useNavigate();
  const [showBtn, setShowBtn] = useState(true)
  const [waitingList, setWaitingList] = useState(true)
  const firstPhase = '2024/10/07 09:00:00';
  const today = moment();


  useEffect(() => {
    let page = new URL(window.location)
    if (page.pathname === "/register" || page.pathname === "thank-you") {
      setShowBtn(false)
    } else {
      setShowBtn(true)
    }
    if (today.diff(moment(firstPhase), 'seconds') > 0) {
      setWaitingList(false)
    }
  })


  return (
    <nav>
      <img src={logo} alt='pmw logo' onClick={() => navigate('/')} />
      {showBtn ? (
        <section className='button__area'>
          {waitingList ? (
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Join The Waiting List
            </button>
          ) : (
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Join Now
            </button>
          )}
        </section>
      ) : null}
    </nav>
  );
}
