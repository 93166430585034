import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Elements, } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Alert, FormControlLabel, Checkbox } from '@mui/material';
import CheckoutForm from "./CheckOutForm";
import '../styles/protege.scss';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');
const stripe = await loadStripe('pk_live_aRr3qYrpVTbyNgBbB7t0jqiy');



export default function Protege() {
  const openDate = '2024/09/30 09:00:00';
  const closeDate = '2024/10/07 09:00:00';
  const today = moment();
  const navigate = useNavigate();
  // const url = 'http://localhost:3999'
  const url = 'https://dev.pmwwebdesign.com'
  const [options, setOptions] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [tcs, setTcs] = useState(false)
  const [loader, setLoader] = useState(true)
  // alerts
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [showAlert, setShowAlert] = useState(false)



  useEffect(() => {
    if (today.diff(moment(openDate), 'seconds') < 0 || today.diff(moment(closeDate), 'seconds') > 0){
      navigate('/register')
    } else{
    let secret;
    let amouunt = {
      amount: 600000
    }
    axios.post(`${url}/mastermind/secret`, amouunt).then((res) => {
      // console.log(res.data.client_secret)
      // setSecret(res.data.client_secret)
      secret = res.data.client_secret
      setOptions({ clientSecret: secret, })
      setLoader(false)
    }).catch((err) => {
      console.log(err)
    })
    }
  }, [])


  const manageError = (error) => {
    setMessage(error)
    setSeverity('error')
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 2500)
  };

  const handleTc = (value) => {
    console.log(value)
    setTcs(value)
  }

  const handleNext = () => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    if (email === "" || phone === "") {
      setMessage("Please fill in email and phone")
      setSeverity('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else if (!email.match(regex)) {
      setMessage("Please insert an email address")
      setSeverity('warning')
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2500)
    } else {
      // TODO: send the data to the db
      let cartData = {
        email: email,
        phone: phone,
      }

      axios.post(`${url}/mastermind/abandon-cart`, cartData)
      .then((res) => {
        console.log(res.data)
        setShowPayment(true)
      }).catch((err) => {
        console.log(err)
        setMessage("Something went wrong, please try again")
        setSeverity('error')
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2500)
      })
    }
  }


  return (
    <section className="payment__form">
      {!showPayment ? (
        <>
          <h2>Personal Details</h2>
          <section className="billing__details">

            <section className="form--row">
              <div>
                <label for="emailI">Email <span id="email">Required field</span></label>
                <input type="email" id="emailI" onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label for="emailI">Phone <span id="email">Required field</span></label>
                <input type="number" id="emailI" onChange={(e) => setPhone(e.target.value)} pattern='[0-9]*' />
              </div>
            </section>
            <button className='submit__bttn' onClick={handleNext}>Next</button>
          </section>
        </>
      ) : (
        <>
          {loader ? (
            <h2>Loading</h2>
          ) : (
            <>
              <h2>Payment Details</h2>
              <Elements stripe={stripe} options={options}>
                <CheckoutForm error={manageError} email={email} phone={phone} tc={tcs}/>
              </Elements>
              <section className="form--row">
                <FormControlLabel control={<Checkbox />} label="I confirm I have read and agree to the terms & conditions" onChange={(e) => handleTc(e.target.checked)} />
              </section>
            </>
          )}
        </>
      )}
      {showAlert ? (
        <Alert severity={severity} sx={{ margin: '1% auto', width: '80%' }} variant="filled">{message}</Alert>
      ) : null}


    </section>
  )
} 