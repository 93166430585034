import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

const AddressForm = (props) => {
  const email = props.email

  useEffect(() => {
    setTimeout(() => {
      if (!props.email){
        props.error('Email field is required')
      }  
    }, 10000)
  },[])

  const handleDetails = (event) => {
    if (event.complete) {
      let client = {
        name: event.value.firstName,
        last: event.value.lastName,
        phone: props.phone,
        email: props.email
      }
      localStorage.setItem('masterClient', JSON.stringify(client))
    }
  }
  return (
    <form>
      <AddressElement options={{
        mode: 'billing',
        display: {
          name: 'split',
        },
        // fields: {
        //   phone: 'always',
        // },
        // validation: {
        //   phone: {
        //     required: 'always',
        //   },
        // },
      }} onChange={(e) => handleDetails(e)} />
    </form>
  );
};

export default AddressForm;